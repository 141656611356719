/* eslint-disable no-unused-vars */
import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import styled from 'styled-components'
import { makeRequest, useWindowDimensions, changelanguages } from '../../helpers'
import { device } from '../../theme/device'
import {
  Spinner,
  Wrapper,
  Subtitle,
  H1,
  H2,
  ButtonMore,
  P,
  NoImage,
  Seo,
} from '../../components'
import CardReated from './componets/card-related'

const WrapperRelated = styled((props) => <Wrapper {...props} />)`
  display: flex;
  margin: 40px 0 0 0
  @media ${device.desktop} {
    margin: 40px 0 0 0
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  > div {
    @media ${device.desktop} {
      margin: 0 16px;
    }
  }
  > div:first-child {
    @media ${device.desktop} {
      margin: 0 16px 0 0;
    }
  }
  > div:lang-child {
    @media ${device.desktop} {
      margin: 0  0 0 16px;
    }
  }
`
const Tag = styled.span`
  background-color: #162934;
  border-radius: 2px;
  font-family: Manrope;
  font-weight: 500;
  color: #fff;
  padding: 5px 5px;
  font-size: 10px;
  text-transform: uppercase;
`

const NewsRoomDetail = ({ params }) => {
  const { slug } = params
  const [newsDetail, setNewsDetail] = useState({})
  const [loading, setLoading] = useState(true)
  const { t, i18n } = useTranslation()
  const size = useWindowDimensions()
  const width = _.get(size, 'width', 0)

  const getNews = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'posts',
      params: {
        lang: changelanguages(i18n.language),
        ...params,
      },
    }).then((resp) => {
      const data = resp.data[0]
      setNewsDetail(data)
      setLoading(false)
    })
  }, [slug])

  useEffect(() => {
    getNews()
  }, [slug])

  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <React.Fragment>
      <Seo
        title={_.get(newsDetail, 'yoast_head_json.title')}
        description={_.get(newsDetail, 'yoast_head_json.description')}
        schema={_.get(newsDetail, 'yoast_head_json.schema')}
        type={_.get(newsDetail, 'yoast_head_json.og_type', {})}
      />
      <Wrapper
        justifyContentD="flex-start"
        // style={{
        //   minHeight: 'calc(200vh - 100px)',
        // }}
        paddingD="90px 0 0"
        flexDirectionD="column"
      >
        <Wrapper
          widthD="1240px"
          flexDirectionD="column"
        >
          <Wrapper
            heightM="120px"
            paddingD="0 0 0 70px"
            alignItemsM="flex-start"
            paddingM="0 0 0 84px"
            justifyContentD="flex-start">
            <ButtonMore link="/newsroom" >{t('newsroom.back')}</ButtonMore>
          </Wrapper>
          <Wrapper
            marginD="0"
            displayD="block"
            widthD="822px"
            style={{
              paddingBottom: width >= 1440 ? '40%' : '52%',
              overflow: 'hidden',
              height: 0,
              position: 'relative',
            }}
            justifyContentD="flex-start">
            {
              _.get(newsDetail, 'featured_image.url')
                ? (
                  <img
                    src={_.get(newsDetail, 'featured_image.url')}
                    style={{
                      position: 'absolute',
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}/>
                ) : (
                  <NoImage widthM="100px"/>
                )
            }

          </Wrapper>
          <Wrapper
            flexDirectionD="column"
            paddingD="0"
            paddingM="32px 25px"
            marginD="0 0 120px"
            widthD="822px">
            <Wrapper
              marginD="36px 0 9px"
              marginm="9px 0"
              flexDirectionD="row"
              flexDirectionM="row"
              alignItemsD="flex-start"
              justifyContentM="flex-start"
              justifyContentD="flex-start">
              <P
                color="primary"
                fontWeightM="medium"
                fontSizeD="xss"
                style={{
                  marginRight: '10px',
                  letterSpacing: '0.5px',
                  textTransform: 'uppercase',
                }}>
                {moment(_.get(newsDetail, 'date')).format('MMMM DD, YYYY')}
              </P>
              <Tag>{_.get(newsDetail, 'category_name')}</Tag>
            </Wrapper>
            <Wrapper
              marginM="0 0 32px 0"
              marginD="0 0 24px"
              justifyContentD="flex-start">
              <H1
                style={{ fontSize: '28px' }}
              >
                {_.get(newsDetail, 'title.rendered')}
              </H1>
            </Wrapper>
            <Wrapper
              justifyContentD="flex-start">
              <div className="rich-text" dangerouslySetInnerHTML={{ __html: _.get(newsDetail, 'content.rendered') }} />
            </Wrapper>
          </Wrapper>
          {
            _.get(newsDetail, 'related_news') && (
              <Wrapper
                paddingM="25px"
                flexDirectionD="column"
                alignItemsM="flex-start"
                justifyContentD="flex-start"
                alignItemsD="flex-start"
              >
                <H2
                  fontSize="3xl"
                  fontSizeD="4xl"
                  fontWeightM="light"
                >{t('newsroom.related')}</H2>
                <WrapperRelated
                  marginM="40px 0 0 0"
                  marginD="40px 0 0"
                  justifyContentD="space-between"
                  alignItemsD="flex-start">
                  {
                    _.map(_.get(newsDetail, 'related_news'), (related) => <CardReated key={related.id} related={related} />)
                  }
                </WrapperRelated>
              </Wrapper>
            )
          }
        </Wrapper>
      </Wrapper>
    </React.Fragment>
  )
}

export default NewsRoomDetail
