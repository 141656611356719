import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { useWindowDimensions } from '../../../helpers'
import {
  Wrapper, H2, P, NoImage,
} from '../../../components'

const Tag = styled.span`
  background-color: #162934;
  border-radius: 2px;
  font-family: Manrope;
  font-weight: 500;
  color: #fff;
  padding: 5px 5px;
  font-size: 10px;
  text-transform: uppercase;
`

const ReadMore = styled.p`
  margin-top: 32px;
  font-family: ${(props) => (props.theme.font.gorditaM)};
  color : ${(props) => (props.theme.colors.primary)};
  font-size: ${(props) => (props.theme.fontSize.sm)};
  line-height: ${(props) => (props.theme.lineHeight.xl)};
  text-decoration: underline;

`

const CardReated = ({ related, t }) => {
  const size = useWindowDimensions()
  const width = _.get(size, 'width', 0)
  return (

    <Wrapper
      style={{
        flex: '0 1 33.333333%',
      }}
      marginD="0"
      marginM="0 0 88px"
      flexDirectionD="column">
      <Wrapper
        marginM="0 0 24px 0"
        marginD="0"
        displayD="block"
        style={{
          paddingBottom: width >= 1440 ? '56%' : '60%',
          overflow: 'hidden',
          height: 0,
          position: 'relative',
        }}
        justifyContentD="flex-start">
        {
          _.get(related, 'featured_image')
            ? (
              <img
                style={{
                  position: 'absolute',
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                src={_.get(related, 'featured_image')}
              />
            ) : (
              <NoImage related/>
            )
        }

      </Wrapper>
      <Wrapper
        marginD="36px 0 9px"
        marginM="0 0 9px 0"
        flexDirectionM="row"
        justifyContentM="flex-start"
        justifyContentD="flex-start">
        <P style={{ marginRight: '10px', textTransform: 'uppercase' }}>
          {moment(_.get(related, 'date')).format('MMMM DD, YYYY')}
        </P>
        <Tag>{_.get(related, 'category_name')}</Tag>
      </Wrapper>
      <Wrapper
        justifyContentD="flex-start">
        <H2
          fontSize="2xl"
          lineHeight="4xl"
          fontWeightM="light"
        >{_.get(related, 'title')}</H2>
      </Wrapper>
      <Wrapper
        justifyContentM="flex-start"
        justifyContentD="flex-start"
        alignItemsM="flex-start"
      >
        <Link style={{ color: '#E20613' }}to={`/newsroom/${_.get(related, 'slug')}/`}>
          <ReadMore>{t('newsroom.more')}</ReadMore>
        </Link>
      </Wrapper>
    </Wrapper>
  )
}

export default withTranslation()(CardReated)
